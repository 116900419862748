@font-face {
  font-family: 'JetBrainsMonoLight';
  src: url('./assets/fonts/JetBrainsMono-Light.ttf') format('truetype');
}

:root {
  --themeColor: #fff;
  --themeBackgroundColor: #2f3032;
  --themeJsonNameColor: #93D0F0;
  --themeJsonValueColor: #CB8F76;
  --themeGold: #c7ac3c;
  --themeIconColor: #888787;
}

body {
  font-family: 'JetBrainsMonoLight', sans-serif;
  margin: 10px;
  padding: 0;
  background-color: var(--themeBackgroundColor);
}

#main {
  width: 60%;
  margin: auto;
}

li {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
  font-size: 50px;
}

.jsonName {
  color: var(--themeJsonNameColor);
}

.jsonValue {
  color: var(--themeJsonValueColor);
}

.jsonBrackets {
  color: var(--themeColor);
}

.ejsTag {
  color: #a91e50;
  font-size: 28px;
}

.sectionTitleValue {
  color: var(--themeColor);
  font-size: 28px;
}

.section {
  border-radius: 5px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
}

.subSectionTitle {
  color: var(--themeGold);
  font-size: 22px;
}

.subSection {
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding-left: 10px;
}

ul li {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0;
  font-size: 20px;
  color: var(--themeColor);
  width: 100%;
  flex-direction: column;
}

ul li svg {
  margin-right: 10px;
  font-size: 20px;
  color: var(--themeIconColor);
}

.iconList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

a {
  color: var(--themeColor);
  text-decoration: underline;
}

p.list {
  margin: 0 0 10px;
}

.multipleList {
  align-items: flex-start;
}

.elaborationList {
  margin-left: 8px;
}

.elaboration {
  margin: 0;
  padding: 0;
}

li span.moreInfo {
    color: gray;
}

.gold {
  color: var(--themeGold);
}

#theme {
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: var(--themeColor);
  width: 50px;
  height: 50px;
}

#theme svg {
  width: 50px;
  height: 50px;
}

.skillList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.skillList li {
  width: fit-content;
  padding: 0;
}

.skill {
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid var(--themeColor);
  min-width: 100px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.sectionCol {
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
  #main {
    width: 70%;
  }

  .title {
    font-size: 45px;
  }
}


@media screen and (max-width: 1000px) {
  #main {
    width: 80%;
  }

  .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 900px) {
  #main {
    width: 90%;
  }
}

@media screen and (max-width: 750px) {
  #main {
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
  .section {
    flex-direction: column;
  }
}

@media screen and (max-width: 620px) {
  .title {
    font-size: 35px;
  }
}

@media screen and (max-width: 545px) {
  .title {
    font-size: 22px;
  }

  .sectionTitleValue {
    font-size: 20px;
  }

  .ejsTag {
    font-size: 20px;
  }

  .subSectionTitle {
    font-size: 18px;
  }

  a {
    font-size: 14px;
  }

  ul li {
    font-size: 14px;
  }

  .skill {
    padding: 10px;
    margin: 0;
  }

  .section {
      padding-left: 10px;
      padding-right: 10px;
  }

  .subSection {
      padding-left: 10px;
      padding-right: 10px;
  }

  span.moreInfo {
      font-size: 14px;
  }

  .elaboration {
      font-size: 16px;
  }

  .elaborationList {
      margin-left: 5px;
  }

  .iconList {
      font-size: 18px;
  }

  ul li svg {
      font-size: 18px;
  }

  ul li {
    font-size: 10px;
  }

  .iconList {
    font-size: 14px;
  }

  .sectionCol {
      flex-direction: column;
  }

  .multipleList {
      align-items: flex-start;
  }

  .section {
      flex-direction: column;
  }
  .skillValue {
    font-size: 14px;
  }
}

.skillIcon img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.projectList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  padding: 0;
}

.project {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--themeBackgroundColor);
  border: 1px solid var(--themeColor);
  width: 46%;
}

.projectTitle {
  font-size: 20px;
  color: var(--themeGold);
  margin-bottom: 16px;
}

.projectDescription {
    font-size: 16px;
    color: var(--themeColor);
}

.projectLink {
    text-decoration: none;
}

.projectListContainer {
  display: flex;
  flex-direction: column;
}

.moreProjects, .moreProjects a {
  text-align: center;
  color: var(--themeColor);
  font-size: 16px;
}

@media screen and (min-width: 1500px) {
  .project {
    width: 47%;
  }
}

@media screen and (max-width: 690px) {
  .project {
    width: 100%;
  }

  .projectTitle {
    font-size: 16px;
  }

  .projectDescription {
    font-size: 14px;
  }
}

.description {
  color: var(--themeColor);
}